@media all and (min-width: 480px) {
  .login {
    padding: 60px 0;
    text-align: center;
  }

  .login form {
    margin: 0 auto;
    max-width: 320px;
  }

  .login button {
    margin: 0 auto;
    max-width: 320px;
  }
  .form-label {
    text-align: left;
    width: 100%;
  }
  .btn-style {
    background-color: #533fab !important;
  }
}
